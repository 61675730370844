/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { useArchiveData, useEase } from "hooks";
import { SEO } from "components";
import { Link } from "gatsby";
import sanityClient from "@sanity/client";
import sanityImageUrl from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import styles from "./archive.module.scss";

const client = sanityClient({
  dataset: "production",
  projectId: "jcmgoa34",
  useCdn: true,
});

const builder = sanityImageUrl(client);

const ProjectsArchivePage: React.FC = () => {
  const { archiveData, archiveYears } = useArchiveData();

  const projectsData = {};

  archiveYears.forEach((year) => {
    const projects = archiveData[year].filter(
      (item) => item.type === "project"
    );

    if (projects.length) {
      projectsData[year] = projects;
    }
  });

  const projectYears = Object.keys(projectsData).reverse();

  const { outQuad } = useEase();
  const getDelay = (a: number, b: number): string =>
    `${150 + 400 * outQuad(a) + 125 * outQuad(b)}ms`;

  return (
    <main className={styles.main}>
      <SEO title="Projects" />
      <ol className={styles.yearList}>
        {projectYears.map((year, yearIndex) => (
          <li key={year} className={styles.yearListItem}>
            <h1 className={styles.yearLabel}>{year}</h1>
            <ol className={styles.archiveList}>
              {projectsData[year].map((archiveItem, itemIndex) => (
                <li
                  key={archiveItem.id}
                  className={styles.archiveListItem}
                  style={{
                    animationDelay: getDelay(
                      yearIndex / projectYears.length,
                      itemIndex / projectsData[year].length
                    ),
                  }}
                >
                  <p className={styles.itemType}>Project</p>
                  {archiveItem.liveSite?.useAsLink ? (
                    <a
                      className={styles.itemLink}
                      href={archiveItem.liveSite.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h2 className={styles.itemTitle}>
                        {archiveItem.information!.title}
                      </h2>
                    </a>
                  ) : (
                    <Link
                      className={styles.itemLink}
                      to={`/${archiveItem.type}/${
                        archiveItem.information!.slug!.current
                      }`}
                    >
                      <h2 className={styles.itemTitle}>
                        {archiveItem.information!.title}
                      </h2>
                    </Link>
                  )}
                  {archiveItem.image?.asset?._id && (
                    <figure className={styles.itemImage}>
                      <img
                        src={builder
                          .image({ _id: archiveItem.image.asset._id })
                          .width(500)
                          .dpr(2)
                          .url()}
                        alt=""
                      />
                    </figure>
                  )}
                  {archiveItem.information!.shortDescription && (
                    <BlockContent
                      className={styles.itemDescription}
                      blocks={archiveItem.information!.shortDescription}
                      dataset="production"
                      projectId="jcmgoa34"
                      renderContainerOnSingleChild={true}
                      serializers={{ container: "section" }}
                    />
                  )}
                  {archiveItem.information!.tags && (
                    <ul className={styles.tagList}>
                      {archiveItem.information!.tags.map((tag) => (
                        <li
                          key={`${archiveItem.id}-${tag!.slug!.current}`}
                          className={styles.tagListItem}
                        >
                          {tag!.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ol>
          </li>
        ))}
      </ol>
    </main>
  );
};

export default ProjectsArchivePage;
